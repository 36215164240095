
import './homeStyles.css'
import logo from '../../Images/AngryJoeLogo.png'
import Nav from '../../Components/Navbar/Nav'
import { FaAngleDown } from 'react-icons/fa'
export default function Hero () {
    return (
        <>
        <div className='heroContainer'>
        <Nav />
            <div className="heroItems">
                <div className="heroItem">
                    <img src={logo} className="heroLogo" alt="Angry Joe's BBQ Logo"></img>
                </div>
                <div className="heroItem">
                    <h1 className="mainTitle">Angry Joe's BBQ</h1>
                    <h3 className="subTitle"> Texas Style BBQ and Catering</h3>
                    <div className="buttonContainer">
                        <a href="#events"><button href="events" className="heroButton">Events</button></a>
                        <a href="#menu"><button href="menu" className="heroButton">Menu</button></a>
                    </div>
                </div>
            </div>
            <div className="spacer heroWave">
                <p className="heroSpacerTitle">About Us</p>
                <div className="heroArrow">
                <FaAngleDown />
                </div>
            </div>
        </div>
        <div className="aboutUsContainer">
        <div></div>
        <div id="about" className="aboutUsItem">
            <h3> About Angry Joe's BBQ</h3>
        </div>
        <div className="aboutUsItem">
        Welcome to Angry Joe's BBQ! We are dedicated to providing our customers with the best BBQ experience possible. Our meats are slow-smoked to perfection using only the finest wood and seasonings. We take pride in our traditional cooking methods and use only the freshest, locally sourced ingredients. Our menu includes all your BBQ favorites such as brisket, pulled pork, ribs, and more. We also offer a variety of sides to complete your meal. Whether you're looking for a casual lunch or a catering option for your next event, we've got you covered. Come visit one of our events or contact us and taste the difference of true Texas BBQ!
        </div>
        <div className="spacer2 background2">
        </div>
        </div>
        </>
    )
}