import { FaTimes, FaBars} from "react-icons/fa"
import { useRef } from 'react'
import "./navStyles.css"
import logo from '../../Images/AngryJoeLogo.png'

export default function Nav () {
    const navRef = useRef();

    const showNavbar = () => {
      navRef.current.classList.toggle("responsive_nav");
      console.log('clicked')
    }
    const hideNavbar =()=>{
      navRef.current.classList.remove("responsive_nav")
    }
    return (
  
             <div className="navbar" style={{y: -50}} animate={{y: 5}}
             transition={{duration: 1}}>
              <div className='navMobileItems' />
  
             <div className="topLogo"/>
  
             <nav ref={navRef} className="navLink">
             <a href="#about"><h2 className="navLinks" onClick={hideNavbar}>About</h2></a>
              <a href="#menu"><h2 className="navLinks" onClick={hideNavbar} >Menu</h2></a>
              <a href="#events"><h2 className="navLinks" onClick={hideNavbar}>Events</h2></a>
              <a href="#contact"><h2 className="navLinks" onClick={hideNavbar}>Contact</h2></a>
              <button 
              className="nav-btn nav-close-btn"
              onClick={showNavbar}>
                  <FaTimes />
              </button>
             </nav>
             <div className="navMobileItems">
             <button 
             className="nav-btn" onClick={showNavbar}>
                <FaBars />
             </button>
             </div>
             </div>
    )
}