
export const eventData = [
    {
        Title: "Angry Joe BBQ",
        Location: "Northern Superior Brewing",
        Date: "May 27",
        Time: "2pm - 8pm",
    },
    {
        Title: "TBD",
        Location: "TBD",
        Date: "TBD",
        Time: "TBD",
    },
    {
        Title: "TBD",
        Location: "TBD",
        Date: "May 27",
        Time: "TBD",
    }

]