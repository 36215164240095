
import './homeStyles.css'
import { useState, useEffect, useRef} from 'react'
import { useInView } from 'react-intersection-observer'
import { menuData } from './menuData.js'

export default function Menu () {
    const [ref, inView] = useInView()
    const [ref2, inView2] = useInView()
    const [ref3, inView3] = useInView()
    const [ref4, inView4] = useInView()
    

    const [ seen, setSeen] = useState(false);
    useEffect(() => {
        if(inView == true ) {
            setSeen(true)
        }
    }, inView)
    return (
        <div id="menu" className="menuContainer">
                <h3>Menu</h3>

                <div ref={ref} className="menuItems">
                    {menuData.map((m, index) => (
                    <div className={!seen ? 'menuItem' : "noItem"} key={index}>
                        <h3 className="menuTitle">{m.name}</h3>
                        <p className="menuPrice">${m.price}</p>
                    </div> 
                ))}
                    
                </div>
                <div className="spacer2 background3"></div>
        </div>
    )
}