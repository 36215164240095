
export const menuData = [
    {
        name: "Pulled Pork, Brisket, Ribs, Beans, Corn Bread",
        price: 39
    },
    {
        name: "Ribs, Beans, CornBread",
        price: 15,
    },
    {
        name: "Pulled Pork and Coleslaw",
        price: 12,
    },
    {
        name: "Burnt Ends, Beans, Cornbread",
        price: 12,
    },
    {
        name: "Brisket (1/4 pound) Cornbread, Beans",
        price: 15,
    },
    {
        name: "Burnt Ends (pork) Beans Coleslaw",
        price: 14,
    },
    {
        name: "Texas Sausage on a bun",
        price: 8,
    },
    {
        name: "Smoked Queso and Corn Chips",
        price: 10,
    }, 
]