import React from 'react'
import { Outlet} from 'react-router-dom'
import Nav from './Components/Navbar/Nav'
import Footer from './Components/Footer/Footer'

const Layout = () => {
  return (
    <div className="App">
        <Outlet />
        <Footer/>
    </div>
  )
}

export default Layout
