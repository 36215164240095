
import {FaPaperPlane, FaInstagramSquare, FaFacebookSquare} from 'react-icons/fa'
export default function Contact () {
    return (
        <div className="contactContainer">
            <h3 id="contact" className="contactTitle">Contact Angry Joe's BBQ</h3>
            <div className="contactItems">
                <div className="contactInfo">
                    <div className="contactItem">
                        <h3>Phone</h3>
                        <p>(705) 971-3471</p>
                        <hr />
                    </div>
                    <div className="contactItem">
                        <h3>Email</h3>
                        <p>jdellasavia@shaw.ca</p>
                        <hr />
                    </div>
                    <div className="contactItem">
                        <h3>Social Media</h3>
                        <div className="socialBox">
                        <a href="https://www.instagram.com/jdellasavia/" target="_blank"><div className="socialIcon"><FaInstagramSquare /></div></a>
                        <a href="" target="_blank"><div className="socialIcon"><FaFacebookSquare /></div></a>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="contactCircle">
                    <div className="pAirplane"><FaPaperPlane /></div>
                </div>
            </div>
        </div>
    )
}