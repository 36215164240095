import logo from '../../Images/AngryJoeLogo.png'
import './footerStyles.css'

export default function Footer () {
    return (
        <div className="footerContainer">
            <div className="footerItems">
                <div className="footerItem">
                    <h3 className="footerTitle">Info</h3>
                    <a href="#about"><p className="footerText">About Us</p></a>
                    <a href="#menu"><p className="footerText">Menu</p></a>
                    <a href="#events"><p className="footerText">Events</p></a>
                    <a href="#contact"><p className="footerText">Contact</p></a>
                </div>
                <div className="footerItem">
                    <h3 className="footerTitle">Contact</h3>
                    <p className="footerText">Phone</p>
                    <p className="footerText">(705 971-3471)</p>
                    <p className="footerText">Email</p>
                    <p className="footerText">jdellasavia@shaw.ca</p>
                </div>
                <div className="footerItem">
                    <h3 className="footerTitle">Follow Us</h3>
                    <p className="footerText">Facebook</p>
                    <p className="footerText">Instagram</p>
                </div>
                <div className="footerItem">
                    <img className="footerLogo" src={logo} alt="Angry Joe's BBQ Logo"></img>
                </div>
            </div>
        </div>
    )
}