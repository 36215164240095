import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './Layout'
import Home from './Routes/HomePage/Home'
import MenuPage from './Routes/Menu/MenuPage'


function App() {
  return (
    <>
    <Routes >
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/menu' element={<MenuPage />}/>
        </Route>
    </Routes>
    </>
  );
}

export default App;
