import Nav from '../../Components/Navbar/Nav'
import Menu from '../HomePage/Menu'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from "react-icons/fa"
import { useState, useEffect, useRef} from 'react'

export default function MenuPage () {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div>
        <div className='otherNav'>
        <Link to='/' className='backButton'>{<FaArrowLeft />}</Link>
        </div>
        <Menu />
        </div>
    )
}