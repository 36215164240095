import Contact from './Contact'
import Instagram from './Instagram'
import UpcomingEvents from './UpcomingEvents'
import Menu from './Menu'
import Hero from './Hero'
export default function Home () {
    return (
        <div className="homeContainer">
            <Hero />
            <Menu />
            <UpcomingEvents />
            <Contact />
        </div>
    )
}