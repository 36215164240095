import { eventData } from './eventsData'
import { Link } from 'react-router-dom'
export default function UpcomingEvents () {
    return (
        <div className="upcomingEventsContainer">
            <h3 id="events" className="eventsTitle">Upcoming Events</h3>
            <div className="eventsItems">
                {eventData.map((eventData, index) => {
                    return (
                        <div className="eventCard" key={index}>
                            <div className='cardLeft'>
                                <div className="date">{eventData.Date}</div>
                                <div className="time">{eventData.Time}</div>
                        </div>
                        <div className="cardMiddle">
                            <div className="cardTitle">{eventData.Title}</div>
                            <div className="cardLocation">{eventData.Location}</div>
                        </div>
                        <div className="cardRight">
                            <Link to='/menu'><button className="eventButton">View Menu</button></Link>
                        </div>
                        </div>
                    )
                })}
            </div>
            <div className="spacer2 background2"></div>
        </div>
    )
}